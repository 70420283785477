// Pantings Assets
import painting1 from "./images/painting/painting1.jpg";
import painting3 from "./images/painting/painting3.jpg";
import painting2 from "./images/painting/painting2.jpg";
import painting4 from "./images/painting/painting4.jpg";
import painting5 from "./images/painting/painting5.jpg";
import painting6 from "./images/painting/painting6.jpg";
import painting7 from "./images/painting/painting7.jpg";
import painting8 from "./images/painting/painting8.jpg";
import painting9 from "./images/painting/painting9.jpg";
import painting10 from "./images/painting/painting10.jpg";

import shareme from "./images/project/shareme.jpg";
import bloggy from "./images/project/bloggy.png";
import airbnb from "./images/project/AirBnb.jpeg";
import reunion from "./images/project/reunion.jpeg";
import formGenerator from "./images/project/formGenerator.jpeg";
import mapOnCube from "./images/project/mapOnCube.png";

// Education Details
export const educationDetails = [
  {
    id: "edu1",
    uniName: {
      hi: "गुरु गोबिंद सिंह इंद्रप्रस्थ विश्वविद्यालय",
      en: "Guru Gobind Singh Indraprastha University",
      ja: "グル・ゴビンド・シン・インドラプラスタ大学",
      de: "Guru Gobind Singh Indraprastha Universität",
      ar: "جامعة جورو جوبيند سينغ إندرابراستا",
      es: "Universidad Guru Gobind Singh Indraprastha",
    },
    location: {
      hi: "दिल्ली, भारत",
      en: "Delhi, India",
      ja: "デリー、インド",
      de: "Delhi, Indien",
      ar: "نيودلهي، الهند",
      es: "Delhi, India",
    },
    courseName: {
      hi: "बी.टेक · कंप्यूटर साइंस इंजीनियरिंग",
      en: "B.Tech · Computer Science Engineering",
      ja: "B.Tech · コンピュータサイエンスエンジニアリング",
      de: "B.Tech · Informatik-Ingenieurwesen",
      ar: "ب.تك · هندسة علوم الحاسب",
      es: "B.Tech · Ingeniería en Ciencias de la Computación",
    },
    marks: {
      hi: "9.1 सीजीपीए",
      en: "9.1 CGPA",
      ja: "9.1 CGPA",
      de: "9.1 CGPA",
      ar: "9.1 المعدل التراكمي",
      es: "9.1 CGPA",
    },
    duration: {
      hi: "2019 - 2023",
      en: "2019 - 2023",
      ja: "2019 - 2023",
      de: "2019 - 2023",
      ar: "2019 - 2023",
      es: "2019 - 2023",
    },
  },
  {
    id: "edu2",
    uniName: {
      hi: "दशमेश पब्लिक स्कूल",
      en: "Dashmesh Public School (CBSE)",
      ja: "ダッシュメッシュ公立学校 (CBSE)",
      de: "Dashmesh Public School (CBSE)",
      ar: "مدرسة داشميش العامة (CBSE)",
      es: "Escuela pública Dashmesh (CBSE)",
    },
    location: {
      hi: "दिल्ली, भारत",
      en: "Delhi, India",
      ja: "デリー、インド",
      de: "Delhi, Indien",
      ar: "نيودلهي، الهند",
      es: "Delhi, India",
    },
    courseName: {
      hi: "12वीं · भौतिकी, रसायन विज्ञान, गणित",
      en: "12th · Physics, Chemistry, Maths",
      ja: "12位・物理学、化学、数学",
      de: "12. · Physik, Chemie, Mathematik",
      ar: "الثاني عشر · الفيزياء، الكيمياء، الرياضيات",
      es: "12º · Física, Química, Matemáticas",
    },
    marks: {
      hi: "82%",
      en: "82%",
      ja: "82%",
      de: "82%",
      ar: "82%",
      es: "82%",
    },
    duration: {
      hi: "2018 - 2019",
      en: "2018 - 2019",
      ja: "2018 - 2019",
      de: "2018 - 2019",
      ar: "2018 - 2019",
      es: "2018 - 2019",
    },
  },
  {
    id: "edu3",
    uniName: {
      hi: "दशमेश पब्लिक स्कूल",
      en: "Dashmesh Public School (CBSE)",
      ja: "ダッシュメッシュ公立学校 (CBSE)",
      de: "Dashmesh Public School (CBSE)",
      ar: "مدرسة داشميش العامة (CBSE)",
      es: "Escuela pública Dashmesh (CBSE)",
    },
    location: {
      hi: "दिल्ली, भारत",
      en: "Delhi, India",
      ja: "デリー、インド",
      de: "Delhi, Indien",
      ar: "نيودلهي، الهند",
      es: "Delhi, India",
    },
    courseName: {
      hi: "10वीं · विज्ञान",
      en: "10th · Science",
      ja: "10位・科学",
      de: "10. · Wissenschaft",
      ar: "العاشر · العلوم",
      es: "10º · Ciencia",
    },
    marks: {
      hi: "9 सीजीपीए",
      en: "9 CGPA",
      ja: "9 CGPA",
      de: "9 CGPA",
      ar: "9 المعدل التراكمي",
      es: "9 CGPA",
    },
    duration: {
      hi: "2016 - 2017",
      en: "2016 - 2017",
      ja: "2016 - 2017",
      de: "2016 - 2017",
      ar: "2016 - 2017",
      es: "2016 - 2017",
    },
  },
];

// Work Details
export const workDetails = [
  {
    id: "work1",
    jobRole: {
      hi: "फ्रंटएंड/रिएक्ट डेवलपर",
      en: "Frontend/React Developer",
      ja: "フロントエンド/リアクト開発者",
      de: "Frontend-/React-Entwickler",
      ar: "مطور الواجهة الأمامية/التفاعل",
      es: "Desarrollador Frontend/React",
    },
    company: {
      hi: "पेटमोजो ∙ इंटर्नशिप",
      en: "Petmojo ∙ Internship",
      ja: "Petmojo ∙ インターンシップ",
      de: "Petmojo ∙ Praktikum",
      ar: "بيتموجو ∙ التدريب",
      es: "Petmojo ∙ Prácticas",
    },
    location: {
      hi: "दिल्ली, भारत",
      en: "Delhi, India",
      ja: "デリー、インド",
      de: "Delhi, Indien",
      ar: "نيودلهي، الهند",
      es: "Delhi, India",
    },
    responsibilities: {
      hi: [
        "रिएक्ट और टेलविंड के साथ आंतरिक उपयोग के लिए डैशबोर्ड पर काम किया।",
        "JWT का उपयोग करके प्रमाणीकरण कार्यान्वित किया गया।",
        "बाकी एपीआई को संभालना और वेब ऐप की कार्यक्षमता बढ़ाना।",
        "निर्मित चालान जनरेटर।",
      ],
      en: [
        "Worked on the Dashboard for internal use, with React and Tailwind.",
        "Implemented authentication using JWT.",
        "Handling rest APIs and increasing functionality of web app.",
        "Built invoice generator.",
      ],
      ja: [
        "React と Tailwind を使用して、社内向けのダッシュボードに取り組みました。",
        "JWTを利用した認証を実装しました。",
        "REST API を処理し、Web アプリの機能を強化します。",
        "請求書ジェネレーターを構築しました。",
      ],
      de: [
        "Arbeitete am Dashboard für den internen Gebrauch mit React und Tailwind.",
        "Authentifizierung mit JWT implementiert.",
        "Umgang mit restlichen APIs und Erweiterung der Funktionalität der Web-App.",
        "Gebauter Rechnungsgenerator.",
      ],
      ar: [
        "تم العمل على لوحة المعلومات للاستخدام الداخلي باستخدام React وTailwind.",
        "تم تنفيذ المصادقة باستخدام JWT.",
        "التعامل مع واجهات برمجة التطبيقات (APIs) المتبقية وزيادة وظائف تطبيق الويب.",
        "بنيت مولد الفاتورة.",
      ],
      es: [
        "Trabajé en el Dashboard para uso interno, con React y Tailwind.",
        "Autenticación implementada usando JWT.",
        "Manejo de API de descanso y aumento de la funcionalidad de la aplicación web.",
        "Generador de facturas construido.",
      ],
    },
    skills: ["React", "Tailwind", "JavaScript", "REST API", "JWT"],
    duration: {
      hi: "सितम्बर 2022 - दिसम्बर 2022",
      en: "Sep 2022 - Dec 2022",
      ja: "2022年9月 - 2022年12月",
      de: "September 2022 – Dezember 2022",
      ar: "سبتمبر 2022 - ديسمبر 2022",
      es: "sept 2022 - dic 2022",
    },
  },
  {
    id: "work2",
    jobRole: {
      hi: "जावास्क्रिप्ट डेवलपर",
      en: "JavaScript Developer",
      ja: "JavaScript開発者",
      de: "JavaScript-Entwickler",
      ar: "مطور جافا سكريبت",
      es: "Desarrollador de JavaScript",
    },
    company: {
      hi: "यूफियस लर्निंग ∙ इंटर्नशिप",
      en: "Eupheus Learning ∙ Internship",
      ja: "ユーフェウスの学習 ∙ インターンシップ",
      de: "Eupheus Learning ∙ Praktikum",
      ar: "أوفيوس التعلم ∙ التدريب",
      es: "Eupheus Learning ∙ Prácticas",
    },
    location: {
      hi: "दिल्ली, भारत",
      en: "Delhi, India",
      ja: "デリー、インド",
      de: "Delhi, Indien",
      ar: "نيودلهي، الهند",
      es: "Delhi, India",
    },
    responsibilities: {
      hi: [
        "इंटरैक्टिव ऑनलाइन पुस्तकें डिज़ाइन और निर्मित की गईं।",
        "छात्रों के लिए विभिन्न इंटरैक्टिव गतिविधियों के लिए लागू तर्क।",
        "पहले से ही लिखित कोड आधार प्रबंधितलना और वेब ऐप की कार्यक्षमता बढ़ाना।",
      ],
      en: [
        "Designed and built interactive online books",
        "Applied logic for various interactive activities for students",
        "Managed already written code base",
      ],
      ja: [
        "インタラクティブなオンライン書籍を設計、構築",
        "学生向けのさまざまなインタラクティブなアクティビティにロジックを応用",
        "すでに書かれたコードベースの管理",
      ],
      de: [
        "Entworfen und erstellt interaktive Online-Bücher",
        "Angewandte Logik für verschiedene interaktive Aktivitäten für Schüler",
        "Verwaltete bereits geschriebene Codebasis",
      ],
      ar: [
        "تصميم وبناء الكتب التفاعلية عبر الإنترنت",
        "المنطق التطبيقي لمختلف الأنشطة التفاعلية للطلاب",
        "إدارة قاعدة التعليمات البرمجية المكتوبة بالفعل",
      ],
      es: [
        "Libros interactivos en línea diseñados y construidos.",
        "Lógica aplicada a diversas actividades interactivas para estudiantes.",
        "Base de código ya escrita administrada",
      ],
    },
    skills: ["CSS", "HTML", "JavaScript", "jQuery"],
    duration: {
      hi: "जुलाई 2022 - अगस्त 2022",
      en: "Jul 2022 - Aug 2022",
      ja: "2022年7月 - 2022年8月",
      de: "Juli 2022 – August 2022",
      ar: "يوليو 2022 - أغسطس 2022",
      es: "jul 2022 - agosto 2022",
    },
  },
  {
    id: "work3",
    jobRole: {
      hi: "वेब डेवलपर",
      en: "Web Developer",
      ja: "ウェブ開発者",
      de: "Web-Entwickler",
      ar: "مطور ويب",
      es: "Desarrollador web",
    },
    company: {
      hi: "डिग्निफाइड डिजिटल सॉल्यूशंस ∙ इंटर्नशिप",
      en: "Dignifyd Digital Solutions ∙ Internship",
      ja: "ディグニファイド デジタル ソリューションズ ∙ インターンシップ",
      de: "Dignifyd Digitale Lösungen ∙ Praktikum",
      ar: "حلول ديجنيفايد الرقمية ∙ التدريب",
      es: "Soluciones Digitales Dignifyds ∙ Prácticas",
    },
    location: {
      hi: "दिल्ली, भारत",
      en: "Delhi, India",
      ja: "デリー、インド",
      de: "Delhi, Indien",
      ar: "نيودلهي، الهند",
      es: "Delhi, India",
    },
    responsibilities: {
      hi: [
        "वास्तविक दुनिया की परियोजनाओं पर काम किया।",
        "ग्राहकों को संभाला और आवश्यकताओं के अनुसार वेबसाइटें डिज़ाइन कीं।",
        "प्रयुक्त कस्टम कोड और टेम्प्लेटिंग।",
      ],
      en: [
        "Worked on real-world projects",
        "Handled clients and designed websites according to requirements",
        "Used custom code and templating",
      ],
      ja: [
        "現実世界のプロジェクトに取り組んだ",
        "クライアントへの対応や、ご要望に応じたWebサイトのデザインなどを行います",
        "使用したカスタムコードとテンプレート",
      ],
      de: [
        "Arbeitete an realen Projekten",
        "Betreuung von Kunden und Gestaltung von Websites gemäß den Anforderungen",
        "Verwendeter benutzerdefinierter Code und Vorlagen",
      ],
      ar: [
        "عملت على مشاريع في العالم الحقيقي",
        "التعامل مع العملاء وتصميم مواقع الويب وفقًا للمتطلبات",
        "استخدام التعليمات البرمجية المخصصة والقوالب",
      ],
      es: [
        "Trabajó en proyectos del mundo real.",
        "Atención a clientes y diseño de sitios web según requerimientos.",
        "Código personalizado y plantillas usados",
      ],
    },
    skills: ["CSS", "HTML", "JavaScript", "Bootstrap"],
    duration: {
      hi: "सितंबर 2021 - नवंबर 2021",
      en: "Sep 2021 - Nov 2021",
      ja: "2021年9月 - 2021年11月",
      de: "September 2021 – November 2021",
      ar: "سبتمبر 2021 - نوفمبر 2021",
      es: "sept 2021 - novi 2021",
    },
  },
  {
    id: "work4",
    jobRole: {
      hi: "वेब डेवलपर",
      en: "Web Developer",
      ja: "ウェブ開発者",
      de: "Web-Entwickler",
      ar: "مطور ويب",
      es: "Desarrollador web",
    },
    company: {
      hi: "फेल्योर टू सक्सेस ∙ इंटर्नशिप",
      en: "Failure to Success ∙ Internship",
      ja: "フェイリュア トゥ サクセス ∙ インターンシップ",
      de: "Failure to Success ∙ Praktikum",
      ar: "فايلور تو سكسيس ∙ تدريب عملي",
      es: "Failure to Success ∙ Pasantía",
    },
    location: {
      hi: "दिल्ली, भारत",
      en: "Delhi, India",
      ja: "デリー、インド",
      de: "Delhi, Indien",
      ar: "نيودلهي، الهند",
      es: "Delhi, India",
    },
    responsibilities: {
      hi: ["एक टीम का हिस्सा बनकर काम किया", "रिस्पॉन्सिव वेब ऐप्स बनाए"],
      en: ["Worked as a part of a team", "Built responsive web apps"],
      ja: ["チームの一員として働いた。", "レスポンシブ Web アプリの構築。"],
      de: [
        "Als Teil eines Teams gearbeitet",
        "Entwickelte responsive Web-Apps",
      ],
      ar: ["عملت كجزء من فريق", "بناء تطبيقات الويب سريعة الاستجابة"],
      es: [
        "Trabajó como parte de un equipo",
        "Aplicaciones web responsivas creadas",
      ],
    },
    skills: [
      "Flask",
      "CSS",
      "Node.js",
      "HTML",
      "MongoDB",
      "JavaScript",
      "jQuery",
      "Bootstrap",
    ],
    duration: {
      hi: "अगस्त 2021 - सितम्बर 2021",
      en: "Aug 2021 - Sep 2021",
      ja: "2021年8月 - 2021年9月",
      de: "August 2021 – September 2021",
      ar: "أغسطس 2021 - سبتمبر 2021",
      es: "agosto 2021 - sept 2021",
    },
  },
];

// Certification Details
export const certificateDetails = [
  {
    id: "cert1",
    certificate: {
      hi: "वेब डेवलपमेंट बूटकैंप",
      en: "Web Development Bootcamp",
      ja: "Web開発ブートキャンプ",
      de: "Webentwicklungs-Bootcamp",
      ar: "المعسكر التدريبي لتطوير الويب",
      es: "Curso intensivo de desarrollo web",
    },
    institution: {
      hi: "Udemy",
      en: "Udemy",
      ja: "Udemy",
      de: "Udemy",
      ar: "يوديمي",
      es: "Udemy",
    },
    instructor: {
      hi: "डॉ. एंजेला यू",
      en: "Dr. Angela Yu",
      ja: "アンジェラ・ユウ博士",
      de: "Dr. Angela Yu",
      ar: "الدكتورة أنجيلا يو",
      es: "Dra. Ángela Yu",
    },
    date: {
      hi: "अप्रैल 2021",
      en: "APR 2021",
      ja: "2021年4月",
      de: "April 2021",
      ar: "أبريل 2021",
      es: "ABRIL 2021",
    },
    link: "https://www.udemy.com/certificate/UC-ca1fa17f-30ec-4581-a9f3-08930a7a0a0c/",
  },
  {
    id: "cert2",
    certificate: {
      hi: "रिएक्ट - संपूर्ण मार्गदर्शिका",
      en: "React - The Complete Guide",
      ja: "React - 完全ガイド",
      de: "React – Der komplette Leitfaden",
      ar: "الدليل الكامل - React",
      es: "React - la guía completa",
    },
    institution: {
      hi: "Udemy",
      en: "Udemy",
      ja: "Udemy",
      de: "Udemy",
      ar: "يوديمي",
      es: "Udemy",
    },
    instructor: {
      hi: "मैक्सिमिलियन श्वार्ज़मुलर",
      en: "Maximilian Schwarzmüller",
      ja: "マクシミリアン・シュヴァルツミュラー",
      de: "Maximilian Schwarzmüller",
      ar: "ماكسيميليان شوارزمولر",
      es: "Maximiliano Schwarzmüller",
    },
    date: {
      hi: "सितंबर 2022",
      en: "SEP 2022",
      ja: "2022 年 9 月",
      de: "September 2022",
      ar: "سبتمبر 2022",
      es: "SEPTIEMBRE 2022",
    },
    link: "https://www.udemy.com/certificate/UC-ad72c371-c738-48c3-a2cd-b70359f92072/",
  },
  {
    id: "cert3",
    certificate: {
      hi: "बीवीकैम हैकथॉन",
      en: "BVICAM Hackathon",
      ja: "BVICAM ハッカソン",
      de: "BVICAM-Hackathon",
      ar: "بفيكام هاكاثون",
      es: "Hackatón BVICAM",
    },
    institution: {
      hi: "भारती विद्यापीठ विश्वविद्यालय",
      en: "Bharati Vidyapeeth University",
      ja: "バーラティ ヴィディヤピース大学",
      de: "Bharati Vidyapeeth Universität",
      ar: "جامعة بهاراتي فيديابيث",
      es: "Universidad Bharati Vidyapeeth",
    },
    instructor: {
      hi: "",
      en: "",
      ja: "",
      de: "",
      ar: "",
      es: "",
    },
    date: {
      hi: "जुलाई 2021",
      en: "JUL 2021",
      ja: "2021年7月",
      de: "JULI 2021",
      ar: "يوليو 2021",
      es: "JULIO 2021",
    },
    link: "https://drive.google.com/file/d/12L_hYjWcmm4P29ASHqJQ6TkKNAkOUbfY/view",
  },
];

// Paintings
export const paintings = [
  {
    id: "pp1",
    painting: painting1,
    title: "Rural man and woman playing music",
    type: "portrait",
  },
  { id: "pp2", painting: painting3, title: "Akshay kumar", type: "portrait" },
  { id: "pp3", painting: painting2, title: "My Grandma", type: "portrait" },
  { id: "pp4", painting: painting4, title: "Lord Shiva", type: "portrait" },
  {
    id: "pp5",
    painting: painting5,
    title: "Flowers in a pot",
    type: "portrait",
  },
  { id: "pp6", painting: painting6, title: "Royal woman", type: "portrait" },
  {
    id: "lp1",
    painting: painting7,
    title: "Abstract pattern",
    type: "landscape",
  },
  {
    id: "lp2",
    painting: painting8,
    title: "Lion facing each other",
    type: "landscape",
  },
  { id: "lp3", painting: painting9, title: "Radha Krishna", type: "landscape" },
  {
    id: "lp4",
    painting: painting10,
    title: "Deer in the sunset",
    type: "landscape",
  },
];

// Skills
export const skillIcons = [
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/html5/html5-original-wordmark.svg",
    alt: "The logo icon for HTML 5",
    title: "HTML 5",
    url: "https://www.w3schools.com/html/",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/css3/css3-original-wordmark.svg",
    alt: "The logo icon for CSS",
    title: "CSS",
    url: "https://www.w3schools.com/css/",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/javascript/javascript-original.svg",
    alt: "The logo icon for JAVASCRIPT",
    title: "JAVASCRIPT",
    url: "https://www.w3schools.com/js/",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/react/react-original-wordmark.svg",
    alt: "The logo icon for React",
    title: "React",
    url: "https://reactjs.org",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/redux/redux-original.svg",
    alt: "The logo icon for Redux",
    title: "Redux",
    url: "https://redux.js.org",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/firebase/firebase-plain-wordmark.svg",
    alt: "The logo icon for FIREBASE",
    title: "FIREBASE",
    url: "https://firebase.google.com",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/mongodb/mongodb-original-wordmark.svg",
    alt: "The logo icon for MongoDB",
    title: "MongoDB",
    url: "https://www.mongodb.com",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/tailwindcss/tailwindcss-plain.svg",
    alt: "The logo icon for Tailwind",
    title: "Tailwind",
    url: "https://tailwindcss.com",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/bootstrap/bootstrap-original.svg",
    alt: "The logo icon for Bootstrap",
    title: "Bootstrap",
    url: "https://getbootstrap.com",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nodejs/nodejs-original-wordmark.svg",
    alt: "The logo icon for Node.JS",
    title: "Node.JS",
    url: "https://nodejs.org/en/",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/nextjs/nextjs-original-wordmark.svg",
    alt: "The logo icon for Next.JS",
    title: "Next.JS",
    url: "https://nextjs.org",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/git/git-original-wordmark.svg",
    alt: "The logo icon for Git",
    title: "Git",
    url: "https://git-scm.com",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/github/github-original-wordmark.svg",
    alt: "The logo icon for Github",
    title: "Github",
    url: "https://github.com",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/heroku/heroku-plain-wordmark.svg",
    alt: "The logo icon for Heroku",
    title: "Heroku",
    url: "https://www.heroku.com",
  },
  {
    source:
      "https://cdn.jsdelivr.net/gh/devicons/devicon/icons/figma/figma-original.svg",
    alt: "The logo icon for Figma",
    title: "Figma",
    url: "https://www.figma.com",
  },
];

// Project Details
export const projects = [
  {
    id: "pjb",
    image: formGenerator,
    title: {
      hi: "फॉर्म जेनरेटर",
      en: "Form Generator",
      ja: "フォームジェネレーター",
      de: "Formulargenerator",
      ar: "مولد النموذج",
      es: "Generador de formularios",
    },
    description: {
      hi: "JSON कच्चे डेटा से जटिल फॉर्म तैयार करने के लिए एक रिएक्ट ऐप। सत्यापन, मेटाडेटा और कई अन्य सुविधाएँ। सेकंडों में फॉर्म बनाएं।",
      en: "A React App for generating complex form from JSON raw data. Validation, metadata and many more features. Create forms in seconds.",
      ja: "JSON 生データから複雑なフォームを生成するための React アプリ。検証、メタデータ、その他多くの機能。フォームを数秒で作成します。",
      de: "Eine React-App zum Generieren komplexer Formulare aus JSON-Rohdaten. Validierung, Metadaten und viele weitere Funktionen. Erstellen Sie Formulare in Sekundenschnelle.",
      ar: "تطبيق React لإنشاء نموذج معقد من بيانات JSON الأولية. التحقق من الصحة والبيانات الوصفية والعديد من الميزات الأخرى. إنشاء النماذج في ثوان.",
      es: "Una aplicación React para generar formularios complejos a partir de datos sin procesar JSON. Validación, metadatos y muchas más funciones. Crea formularios en segundos.",
    },
    link: "https://frontend-assignment-mohit-thapliyal.vercel.app",
    github: "https://github.com/Mohit-Thapliyal/Frontend-Assignment",
  },
  {
    id: "pjk",
    image: bloggy,
    title: {
      hi: "ब्लॉगी",
      en: "Bloggy",
      ja: "ブロギー",
      de: "Bloggy",
      ar: "مدون",
      es: "Bloggy",
    },
    description: {
      hi: "नेक्स्ट.जेएस और टेलविंड का उपयोग करके अपने विचारों, विचारों और अनुभवों को दुनिया के साथ साझा करने के लिए भावुक लेखकों और पाठकों का समुदाय।",
      en: "Community of passionate writers and readers to share your thoughts, ideas, and experiences with the world made using Next.js and Tailwind.",
      ja: "Next.js と Tailwind を使用して作成された世界とあなたの考え、アイデア、経験を共有する、情熱的なライターと読者のコミュニティ。",
      de: "Community leidenschaftlicher Autoren und Leser, um Ihre Gedanken, Ideen und Erfahrungen mit der Welt zu teilen, die Sie mit Next.js und Tailwind gemacht haben.",
      ar: "مجتمع من الكتاب والقراء المتحمسين لمشاركة أفكارك وأفكارك وتجاربك مع العالم الذي تم إنشاؤه باستخدام Next.js وTailwind.",
      es: "Comunidad de escritores y lectores apasionados para compartir sus pensamientos, ideas y experiencias con el mundo creado con Next.js y Tailwind.",
    },
    link: "https://bloggy-mohit-thapliyal.vercel.app",
    github: "https://github.com/Mohit-Thapliyal/Bloggy",
  },
  {
    id: "pje",
    image: mapOnCube,
    title: {
      hi: "घन पर मानचित्र",
      en: "Map on Cube",
      ja: "キューブ上のマップ",
      de: "Karte auf Würfel",
      ar: "خريطة على المكعب",
      es: "Mapa en cubo",
    },
    description: {
      hi: "एक वेबएप जो गूगल मैप की त्वचा के साथ एक क्यूब उत्पन्न करता है। अब, इसे आज़माएँ और अपने स्वयं के मानचित्र की त्वचा चुनें।",
      en: "A webapp that generates a cube with the skin of google map. Now, try this out and choose your own map's skin.",
      ja: "Google マップのスキンを使用して立方体を生成する Web アプリ。さあ、これを試して、独自のマップのスキンを選択してください。",
      de: "Eine Webanwendung, die einen Würfel mit dem Skin von Google Map generiert. Probieren Sie es jetzt aus und wählen Sie den Skin Ihrer eigenen Karte.",
      ar: "تطبيق ويب يقوم بإنشاء مكعب بجلد خريطة جوجل. الآن، جرب ذلك واختر شكل خريطتك الخاصة.",
      es: "Una aplicación web que genera un cubo con la apariencia de google map. Ahora, prueba esto y elige la apariencia de tu propio mapa.",
    },
    link: "https://map-on-cube-mohit-thapliyal.vercel.app/",
    github: "https://github.com/Mohit-Thapliyal/map-on-cube",
  },
  {
    id: "pj5",
    image: shareme,
    title: {
      hi: "शेयरमी",
      en: "Shareme",
      ja: "シェアミー",
      de: "Shareme",
      ar: "شيرمي",
      es: "Shareme",
    },
    description: {
      hi: "एक प्लेटफ़ॉर्म जहां आप छवियां जोड़ सकते हैं और साथ ही दूसरों द्वारा पोस्ट की गई छवियां भी देख सकते हैं, Google प्रमाणीकरण, विवेक, प्रतिक्रिया और टेलविंड का उपयोग करता है।",
      en: "A platform where you can add images as well as see the images posted by other, uses google authentication, sanity, react and tailwind.",
      ja: "画像を追加したり、他の人が投稿した画像を見ることができるプラットフォームで、Google 認証、sanity、react、tailwind が使用されます。",
      de: "Eine Plattform, auf der Sie Bilder hinzufügen und die von anderen geposteten Bilder sehen können, nutzt Google-Authentifizierung, Sanity, React und Tailwind.",
      ar: "منصة يمكنك من خلالها إضافة الصور وكذلك مشاهدة الصور التي ينشرها الآخرون، تستخدم مصادقة جوجل، والعقلانية، والتفاعل، والرياح الخلفية.",
      es: "Una plataforma donde puedes agregar imágenes y ver las imágenes publicadas por otros, utiliza autenticación de Google, cordura, reacción y viento de cola.",
    },
    link: "https://shareme.mohitthapliyal.in",
    github: "https://github.com/Mohit-Thapliyal/shareme",
  },
  {
    id: "pja",
    image: reunion,
    title: {
      hi: "लिविंगस्पेस",
      en: "LivingSpace",
      ja: "リビングスペース",
      de: "LivingSpace",
      ar: "ليفينج سبيس",
      es: "LivingSpace",
    },
    description: {
      hi: "फ़िल्टरेशन और खोज कार्यक्षमता पर चार स्तरों वाला एसपीए। टेलविंड का उपयोग करके स्टाइल किया गया और वर्सेल पर होस्ट किया गया।",
      en: "SPA with four level on filteration and search functionality. Styled using tailwind and hosted on vercel.",
      ja: "4 つのレベルのフィルタリングと検索機能を備えた SPA。 tailwind を使用してスタイル設定され、vercel でホストされています。",
      de: "SPA mit vierstufiger Filter- und Suchfunktion. Mit Rückenwind gestylt und auf Vercel gehostet.",
      ar: "SPA مع أربعة مستويات لوظيفة التصفية والبحث. تم تصميمه باستخدام الريح الخلفية واستضافته على vercel.",
      es: "SPA con cuatro niveles de funcionalidad de filtrado y búsqueda. Diseñado con viento de cola y alojado en vercel.",
    },
    link: "https://livingspace-seven.vercel.app/",
    github: "https://github.com/Mohit-Thapliyal/Reunion-Assignment",
  },
  {
    id: "pj0",
    image: airbnb,
    title: {
      hi: "एयरबीएनबी क्लोन",
      en: "AirBnb Clone",
      ja: "リビングスペース",
      de: "AirBnb-Klon",
      ar: "استنساخ إير بي إن بي",
      es: "Clon de AirBnb",
    },
    description: {
      hi: "Next.js और टेलविंड का उपयोग करके पूर्ण प्रतिक्रियाशील AirBnb क्लोन का निर्माण। इंटरैक्टिव खोज बार और दिनांक चयनकर्ता के साथ आधुनिक और चिकना डिज़ाइन।",
      en: "Full Responsive AirBnb clone build using Next.js and tailwind. Modern and sleek design with a interactive search bar and date selector.",
      ja: "Next.js と tailwind を使用したフル レスポンシブ AirBnb クローン ビルド。インタラクティブな検索バーと日付セレクターを備えたモダンで洗練されたデザイン。",
      de: "Vollständig responsiver AirBnb-Klon-Build mit Next.js und Rückenwind. Modernes und elegantes Design mit interaktiver Suchleiste und Datumsauswahl.",
      ar: "إنشاء نسخة AirBnb كاملة الاستجابة باستخدام Next.js والرياح الخلفية. تصميم حديث وأنيق مع شريط بحث تفاعلي ومحدد التاريخ.",
      es: "Creación de clones de AirBnb con capacidad de respuesta completa utilizando Next.js y viento de cola. Diseño moderno y elegante con barra de búsqueda interactiva y selector de fecha.",
    },
    link: "https://hotels-liard-psi.vercel.app",
    github: "https://github.com/Mohit-Thapliyal/Airbnb",
  },
  // {
  //   id: "pj1",
  //   image: portfolio,
  //   title: "Portfolio",
  //   description:
  //     "My personal website showcases my web designing and web development skills. Beautifully designed with react and tailwind.",
  //   link: "https://mohitthapliyal.in",
  //   github: "https://github.com/Mohit-Thapliyal/Portfolio",
  // },
  // {
  //   id: "pj5",
  //   image: bg5,
  //   title: "Car Price Predictor",
  //   description:
  //     "A Machine Learning project which can predict the selling price of a car based on car details. Build using Flask and Bootstrap.",
  //   link: "https://car-price-predictor-mt.herokuapp.com/",
  //   github: "https://github.com/Mohit-Thapliyal/Car-Price-Predictor",
  // },
  // {
  //   id: "pj3",
  //   image: bg3,
  //   title: "Meetups",
  //   description:
  //     "Next.js project where you can add meetup detail by simply adding the details in the meetup form and adding any image link of your choice.",
  //   link: "https://meetups.mohitthapliyal.in/",
  //   github: "https://github.com/Mohit-Thapliyal/NextJS-Meet-Up",
  // },
  // {
  //   id: "pj4",
  //   image: bg4,
  //   title: "Quotes",
  //   description:
  //     "Here you can add quotes and even comment on the quotes written by others. The URL changes dynamically according to the quotes.",
  //   link: "https://quotes.mohitthapliyal.in/quotes",
  //   github: "",
  // },
  // {
  //   id: "pj2",
  //   image: bg2,
  //   title: "Corporate Wisdom",
  //   description:
  //     "A Custom designed website for a well-known book author Dr. Meena Sinha.",
  //   link: "",
  //   github: "",
  // },
];
