import { Link } from "react-router-dom";
import { HiDownload } from "react-icons/hi";
import { motion } from "framer-motion";
import profilePicColor from "../../images/profile/mohit-color.png";
import profilePic from "../../images/profile/mohit.png";
import AnimatedPage from "../UI/AnimatedPage";
import { FiSun, FiMoon } from "react-icons/fi";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../UI/LanguageSelector";
import cx from 'classnames'

const Main = ({ toggleDarkMode, isDarkMode }) => {
  const { t } = useTranslation();
  // Get the text direction from the translation
  const textDirection = t("common.text-direction");
  return (
    <AnimatedPage>
      <main style={{ direction: textDirection }} className="relative pt-[15vh] md:pt-0 flex flex-col md:justify-center md:items-center w-screen min-h-screen">
        <div className="fixed top-0 z-20 flex items-center justify-between w-full px-3 py-2 lg:hidden bg-white dark:bg-secondary dark:text-white">
          <span className="text-lg font-bold tracking-wider font-comfortaa">
            {t("main.portfolio")}
          </span>
          {/* <span className="text-base font-bold">Mohit Thapliyal</span> */}
          <div className="flex gap-4">
            <LanguageSelector isMain={true} />
            <button
              className="p-2 rounded-full bg-rose-500 dark:bg-primary text-white dark:text-gray-200"
              onClick={toggleDarkMode}
            >
              {isDarkMode ? <FiSun size={24} /> : <FiMoon size={24} />}
            </button>
          </div>
        </div>

        <motion.div className="flex gap-7 h-[70vh] xl:w-11/12 flex-col justify-between cursor-default lg:flex-row-reverse md:justify-evenly items-center xl:gap-0">
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{
              scale: 1,
              opacity: 1,
            }}
            whileHover={{
              scale: 1.03,
            }}
            transition={{
              duration: 0.05,
            }}
            className="flex justify-center w-7/12 duration-100 ease-in-out md:justify-end md:w-5/12 lg:w-3/12 xl:w-[28%]"
          >
            <img
              className={cx("block dark:hidden",{"scale-x-[-100%]":textDirection==="rtl"})}
              src={profilePicColor}
              alt="profile pic"
            />
            <img
              className={cx("hidden dark:block",{"scale-x-[-100%]":textDirection==="rtl"})}
              src={profilePic}
              alt="profile pic"
            />
          </motion.div>
          <motion.div
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{
              scale: 1,
              opacity: 1,
            }}
            transition={{
              duration: 0.05,
            }}
            className="relative flex-grow md:flex-grow-0 w-5/6 p-3 py-5 text-left duration-300 ease-linear rounded-lg md:w-9/12 lg:w-6/12 md:p-10 lg:pb-8 lg:p-10 xl:pl-3 lg:pr-0 xl:pb-16"
          >
            <div className="flex h-full flex-col justify-evenly md:gap-5 lg:z-10 relative">
              <h1
                className="self-start text-rose-500 dark:text-highlight text-3xl tracking-normal md:text-5xl xl:text-6xl font-bold uppercase [word-spacing:1rem] text-shadow-lg"
              >
                {t("main.f-name")}{" "}
                <span className="text-slate-800 dark:text-gray-200">
                  {t("main.l-name")}
                </span>
              </h1>
              <h4
                className="self-start uppercase mt-2 text-sm md:text-xl font-semibold font-mono text-gray-800 dark:text-gray-200"
              >
                {t("main.i-am")}{" "}
                <span className="inline-block rounded-sm px-2 py-1 text-sm font-semibold tracking-widest text-white md:text-lg bg-gradient-to-r from-red-500 via-pink-500 to-rose-500">
                  {t("main.role")}
                </span>
              </h4>

              <p
                className="my-2 text-sm leading-6 sm:text-base md:tracking-normal md:leading-7 sm:font-medium lg:leading-8 text-justify lg:w-full xl:w-4/5 lg:text-base text-slate-700 dark:text-gray-200"
              >
                {t("main.des-one")} <br className="hidden md:block" />{" "}
                {t("main.des-two")}
              </p>
              <div className="flex gap-4 items-center text-gray-800">
                <Link
                  to="contact"
                  className="px-3 text-sm lg:text-base text-white dark:text-primary shadow-md py-2 font-semibold transition-all duration-500 ease-out rounded-full cursor-pointer hover:scale-110 bg-rose-500 dark:bg-highlight md:px-5"
                >
                  {t("main.hire")}
                </Link>
                <a
                  href={
                    "https://docs.google.com/document/d/1TZY3x4PmRfx0eFCds9ul9M-1iHG3z83rBOvlmp_F0bY/edit?usp=sharing"
                  }
                  target="blank"
                  className="flex gap-1 text-rose-500 dark:text-highlight text-sm shadow-md lg:text-base px-3 py-2 font-semibold transition-all duration-500 bg-transparent border border-rose-400 dark:border-highlight rounded-full md:border-2 hover:scale-110 hover:bg-white dark:hover:bg-highlight dark:hover:text-primary md:px-5"
                >
                  {t("main.resume")}
                  <HiDownload className="text-lg lg:text-xl font-thin" />
                </a>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </main>
    </AnimatedPage>
  );
};

export default Main;
