import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaLanguage } from "react-icons/fa";

const LanguageSelector = ({isMain}) => {
  const { t, i18n } = useTranslation();
  const textDirection = t("common.text-direction");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setIsDropdownOpen(false); // Close the dropdown when a language is selected
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    console.log("holla");
  };

  const handleOutsideClick = (event) => {
    // Close the dropdown if a click occurs outside of the button or dropdown
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !event.target.classList.contains("language-button")
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    // Add a click event listener to the window
    window.addEventListener("click", handleOutsideClick);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const languages = [
    { code: "hi", label: "हिन्दी" },
    { code: "en", label: "English" },
    { code: "ja", label: "日本語" },
    { code: "de", label: "Deutsch" },
    { code: "ar", label: "العربية" },
    { code: "es", label: "Español" },
    // Add more languages as needed
  ];

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <div onClick={toggleDropdown}>
        <button
          type="button"
          className={`flex gap-2 items-center px-4 py-2 rounded-md transition duration-300 ease-in-out bg-rose-500 ${isMain?"dark:bg-primary":"dark:bg-secondary"} text-white font-medium hover:scale-105 hover:shadow-md`}
          // Toggle dropdown visibility on button click
        >
          <FaLanguage size={25} /> {i18n.language}
        </button>
      </div>
      {isDropdownOpen && ( // Render the dropdown when isDropdownOpen is true
        <div style={{ direction: textDirection }} className="origin-top-right absolute right-0 mt-2 w-32 rounded-md shadow-lg bg-white dark:bg-tertiary ring-1 ring-black ring-opacity-5">
          <div className="py-1 px-1">
            {languages.map((lang) => (
              <button
                key={lang.code}
                onClick={() => changeLanguage(lang.code)}
                className="block text-start px-4 py-2 text-sm rounded-md text-gray-700 dark:text-primary dark:hover:text-white dark:hover:bg-secondary hover:bg-gray-100 hover:text-gray-900 w-full"
              >
                {lang.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
